<template>
  <div class="registerContainer">
    <div class="register-wrap container">
      <b-form @submit.prevent="submit" class="registernextform">
        <b-row>
					<b-col style="display: flex; justify-content: flex-start">
						<img class="back" src="../assets/back.svg" @click="$router.push('/register')" />
					</b-col>
				</b-row>
        <h4>Owner Details</h4>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="ownername" class="control-label">Name</label>
              <b-form-input
                id="LegalName"
                v-model.lazy="$v.register.ownername.$model"
                class="form-control input-focus"
                placeholder="Owner Name"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="ownernumber" class="control-label"
                >Contact Number</label
              >
              <b-form-input
                type="number"
                id="ownernumber"
                v-model.lazy="$v.register.ownernumber.$model"
                class="form-control input-focus"
                placeholder="Owner Contact Number"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="owneremail" class="control-label"
                >Personal Email</label
              >
              <b-form-input
                id="owneremail"
                v-model.lazy="$v.register.owneremail.$model"
                class="form-control input-focus"
                placeholder="Owner Personal Email"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <!-- ############################################################################################################ -->
        <h4>Tour Department Details</h4>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="tdname" class="control-label">Name</label>
              <b-form-input
                id="tdname"
                v-model.lazy="$v.register.td_headname.$model"
                class="form-control input-focus"
                placeholder="Tour Dpt. Head Name"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="tdnumber" class="control-label">Contact Number</label>
              <b-form-input
                type="number"
                id="tdnumber"
                v-model.lazy="$v.register.td_headnumber.$model"
                class="form-control input-focus"
                placeholder="Tour Dept. Contact Number"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="tdemail" class="control-label">Personal Email</label>
              <b-form-input
                id="tdemail"
                v-model.lazy="$v.register.td_heademail.$model"
                class="form-control input-focus"
                placeholder="Tour Dept. Head Personal Email"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <!-- ############################################################################################################ -->
        <h4>Finance Department Details</h4>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="fnname" class="control-label">Name</label>
              <b-form-input
                id="fnname"
                v-model.lazy="$v.register.fn_headname.$model"
                class="form-control input-focus"
                placeholder="Finance Dpt. Head Name"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="fnnumber" class="control-label">Contact Number</label>
              <b-form-input
                type="number"
                id="fnnumber"
                v-model.lazy="$v.register.fn_headnumber.$model"
                class="form-control input-focus"
                placeholder="Finance Dept. Contact Number"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="fnemail" class="control-label">Personal Email</label>
              <b-form-input
                id="fnemail"
                v-model.lazy="$v.register.fn_heademail.$model"
                class="form-control input-focus"
                placeholder="Finance Dept. Head Personal Email"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <!-- ############################################################################################################ -->
        <h4>Payment Department Details</h4>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="payname" class="control-label">Name</label>
              <b-form-input
                id="payname"
                v-model.lazy="$v.register.pay_headname.$model"
                class="form-control input-focus"
                placeholder="Payment Dpt. Head Name"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="paynumber" class="control-label">Contact Number</label>
              <b-form-input
                type="number"
                id="paynumber"
                v-model.lazy="$v.register.pay_headnumber.$model"
                class="form-control input-focus"
                placeholder="Payment Dept. Contact Number"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="payemail" class="control-label">Personal Email</label>
              <b-form-input
                id="payemail"
                v-model.lazy="$v.register.pay_heademail.$model"
                class="form-control input-focus"
                placeholder="Payment Dept. Head Personal Email"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 20px">
          <b-col md="12">
            <div class="form-group">
              <!-- <label for="payemail" class="control-label">Are you the owner of the company? If not, do not select</label> -->
              <b-form-checkbox
                id="checkbox-1"
                v-model="$v.register.isOwner.$model"
                name="checkbox-1"
              >
              <span style="margin: 0 10px ">Are you the owner of the company? If not, do not select</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 20px">
          <b-col md="12">
            <b-button class="submit pb-2" variant="dark" type="submit"
              >Register</b-button
            >
          </b-col>
        </b-row>
        <b-row class="error">
          <b-col style="text-align: center">
            <span
              class="text-danger"
              v-if="
                !$v.register.ownername.required && $v.register.ownername.$error
              "
              >Owner: Owner Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.ownernumber.required &&
                $v.register.ownernumber.$error
              "
              >Owner: Owner Contact is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.ownernumber.$params.maxLength &&
                $v.register.ownernumber.$error
              "
              >Owner: Mobile number should not be more than
              {{ $v.register.ownernumber.$params.maxLength.max }} digits</span
            >

            <span
              class="text-danger"
              v-else-if="
                !$v.register.owneremail.required &&
                $v.register.owneremail.$error
              "
              >Owner: Email Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.owneremail.email && $v.register.owneremail.$error
              "
              >Owner: Email Address is invalid</span
            >
            <!-- #################################################### -->
            <span
              class="text-danger"
              v-else-if="
                !$v.register.td_headname.required &&
                $v.register.td_headname.$error
              "
              >Tour Dept: Head Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.td_headnumber.required &&
                $v.register.td_headnumber.$error
              "
              >Tour Dept: Head Contact is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.td_headnumber.$params.maxLength &&
                $v.register.td_headnumber.$error
              "
              >Tour Dept: Mobile Number should not be more than
              {{ $v.register.td_headnumber.$params.maxLength.max }} digits</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.td_heademail.required &&
                $v.register.td_heademail.$error
              "
              >Tour Dept: Head Email Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.td_heademail.email &&
                $v.register.td_heademail.$error
              "
              >Tour Dept: Head Email Address is invalid</span
            >
            <!-- #################################################### -->
            <span
              class="text-danger"
              v-else-if="
                !$v.register.fn_headname.required &&
                $v.register.fn_headname.$error
              "
              >Finance Dept: Head Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.fn_headnumber.required &&
                $v.register.fn_headnumber.$error
              "
              >Finance Dept: Head Contact is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.fn_headnumber.$params.maxLength &&
                $v.register.fn_headnumber.$error
              "
              >Finance Dept: Mobile Number should not be more than
              {{ $v.register.fn_headnumber.$params.maxLength.max }} digits</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.fn_heademail.required &&
                $v.register.fn_heademail.$error
              "
              >Finance Dept: Head Email Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.fn_heademail.email &&
                $v.register.fn_heademail.$error
              "
              >Finance Dept: Head Email Address is invalid</span
            >
            <!-- #################################################### -->
            <span
              class="text-danger"
              v-else-if="
                !$v.register.pay_headname.required &&
                $v.register.pay_headname.$error
              "
              >Payment Dept: Head Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.pay_headnumber.required &&
                $v.register.pay_headnumber.$error
              "
              >Payment Dept: Head Contact is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.pay_headnumber.$params.maxLength &&
                $v.register.pay_headnumber.$error
              "
              >Payment Dept: Mobile Number should not be more than
              {{ $v.register.pay_headnumber.$params.maxLength.max }} digits</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.pay_heademail.required &&
                $v.register.pay_heademail.$error
              "
              >Payment Dept: Head Email Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.pay_heademail.email &&
                $v.register.pay_heademail.$error
              "
              >Payment Dept: Head Email Address is invalid</span
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "RegisterNext",
  data() {
    return {
      register: {
        ownername: "",
        ownernumber: "",
        owneremail: "",
        td_headname: "",
        td_headnumber: "",
        td_heademail: "",
        fn_headname: "",
        fn_headnumber: "",
        fn_heademail: "",
        pay_headname: "",
        pay_headnumber: "",
        pay_heademail: "",
        second_contact: "",
        isOwner: 0,
      },
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = this.$store.state.register.register
        const payload = Object.assign(data, this.register)
        await this.$store.dispatch('register/register', payload)
        await this.$store.dispatch('register/postRegister', payload).then((response) => {
          this.$router.push("/")
        }).catch((error) => {
          this.$router.push("/register")
        })
      }
    },
  },
  validations: {
    register: {
      ownername: {
        required,
      },
      ownernumber: {
        required,
        maxLength: maxLength(12),
      },
      owneremail: {
        required,
        email,
      },
      td_headname: {
        required,
      },
      td_headnumber: {
        required,
        maxLength: maxLength(12),
      },
      td_heademail: {
        required,
        email,
      },
      fn_headname: {
        required,
      },
      fn_headnumber: {
        required,
        maxLength: maxLength(12),
      },
      fn_heademail: {
        required,
        email,
      },
      pay_headname: {
        required,
      },
      pay_headnumber: {
        required,
        maxLength: maxLength(12),
      },
      pay_heademail: {
        required,
        email,
      },
      isOwner: {}
    },
  },
};
</script>

<style lang="scss" scoped>
.registerContainer {
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Gilroy", sans-serif;

  .register-wrap {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .registernextform {
    padding: 10% 5% 5% 5%;
    margin: 10% 0;
    background: white;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);

    .back {
      height: 30px;
      cursor: pointer;
      margin-bottom: 20px;
    }
    .back:hover {
      -webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
      -moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
      box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
    }

    .back:focus {
      outline: none!important;
    }
  }

  .text-danger {
    transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
    -mox-transition: all 500ms ease-in-out;
  }

  .selection {
    width: 100%;
    padding: 9% 0px;
  }

  .submit {
    width: 100%;
  }

  .input-focus {
    border-radius: 4px;
  }

  .input-focus:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #121212;
    transition: all 500ms ease-in;
  }
}
</style>
